body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.section {
  padding-left: 4px;
  color: white;
  margin-bottom: 8px;
  background-color: rgba(255,255,255,0.18);
  padding-top: 3px;
  padding-bottom: 3px;
}

.with-background-image {
  background-image: url(/profileBackground.jpg);
  color:white;
  min-height:100vh;
}

.badge {
  margin-right:6px;
}

.styled-link {
  color: rgba(255,255,255,0.8);
  animation-duration: 800ms;
}

.styled-link:hover {
  color: white;
}
